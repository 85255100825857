import {unwrapResult} from '@reduxjs/toolkit'
import { Button, ConfigProvider, Modal } from 'antd'
import Layout, { Content, Footer, Header } from 'antd/lib/layout/layout'
import deDE from 'antd/lib/locale/de_DE'
import { KeycloakInstance } from 'keycloak-js'
import moment from 'moment'
import 'moment/locale/de'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainNavigation from '../common/components/MainNavigation/MainNavigation'
import SystemTray from '../common/components/SystemTray/SystemTray'
import { KeycloakContext } from '../common/contexts/keycloak'
import {
  authenticated,
  selectAuthentication,
} from '../features/authentication/authenticationSlice'
import {
  fetchCurrentUser,
  selectCurrentUser
} from '../features/currentUser/currentUserSlice'
import { fetchAll } from '../features/memberships/membershipsSlice'
import DutyRoster from '../pages/DutyRoster'
import DutyRosters from '../pages/DutyRosters'
import OffDuties from '../pages/OffDuties'
import Staff from '../pages/Staff'
import styles from './App.module.css'
import { useAppDispatch, useAppSelector } from './hooks'
import Keycloak from './Keycloak'

moment.locale('de-de')

function App() {
  const [keycloak, setKeycloak] = useState<KeycloakInstance>()
  const authentication = useAppSelector(selectAuthentication)
  const currentUser = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()

  useEffect(() => {
    Keycloak.init({ onLoad: 'login-required' }).then((isAuthenticated) => {
      setKeycloak(Keycloak)

      if (!isAuthenticated) {
        console.error('returned from login but not authenticated')
      } else {
        dispatch(
          authenticated({
            accessToken: Keycloak.token!,
            refreshToken: Keycloak.refreshToken!,
          })
        )
      }
    })
  }, [dispatch])

  // load the currentUser
  useEffect(() => {
    async function loadCurrentUser() {
      const result = await dispatch(fetchCurrentUser())
      unwrapResult(result)
    }
    if (authentication.isAuthenticated) {
      loadCurrentUser()
    }
  }, [dispatch, authentication.isAuthenticated])

  // load the memberships of the current user's institution
  useEffect(() => {
    async function loadMemberships() {
      const result = await dispatch(fetchAll({institutionUuid: currentUser.instututionUuid}))
      unwrapResult(result)
    }
    if (authentication.isAuthenticated && currentUser?.instututionUuid) {
      loadMemberships()
    }
  }, [authentication.isAuthenticated, currentUser?.instututionUuid, dispatch])

  return (
    <KeycloakContext.Provider value={keycloak}>
      <ConfigProvider locale={deDE}>
        {authentication.isAuthenticated && (
          <Router>
            <Layout className={styles.root}>
              <Header className={styles.header}>
                <img
                  src="/business_logo.png"
                  alt="company logo"
                  className={styles.logo}
                />
                <MainNavigation className={styles.mainNavigation} />
                <SystemTray />
              </Header>
              <Content className={styles.content} id="main-content">
                <Switch>
                  <Route exact path="/" component={DutyRosters}></Route>
                  <Route path="/duty-roster/:id" component={DutyRoster} />
                  <Route exact path="/offDuties" component={OffDuties}></Route>
                  <Route exact path="/staff" component={Staff}></Route>
                </Switch>
              </Content>
              <Footer className={styles.footer}>
                #medERP v1.6.1 ©2023 created by
                <img
                  src="/mewedo-logo.png"
                  className={styles.mewedoLogo}
                  alt="mewedo logo"
                />
              </Footer>
            </Layout>
          </Router>
        )}

        <Modal
          title="Session abgelaufen"
          centered
          visible={authentication.isSessionExpired}
          closable={false}
          footer={null}
          bodyStyle={{ display: 'flex', flexDirection: 'column' }}
        >
          <p>
            Ihre Nutzersession ist abgelaufen und konnte nicht automatisch
            erneuert werden. Bitte melden Sie sich neu an.
          </p>
          <Button
            onClick={() => Keycloak.login()}
            type="primary"
            style={{ alignSelf: 'flex-end' }}
          >
            Zum Login
          </Button>
        </Modal>
      </ConfigProvider>
    </KeycloakContext.Provider>
  )
}

export default App
