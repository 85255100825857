import { unwrapResult } from '@reduxjs/toolkit'
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Upload
} from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import { RcFile } from 'antd/lib/upload'
import { Component, createRef } from 'react'
import config from '../../../app/config'
import { EEmploymentType } from '../../../features/memberships/employmentType'
import Initials from '../Initials'
import ColorInput from './components/ColorInput'
import styles from './StaffForm.module.css'
const { Option } = Select

interface Props {
  visible: boolean
  handleFinish: (values: IStaffFormValues) => Promise<void>
  initialValues: IStaffFormValues | null
  imageUrl: string | undefined
  replaceAvatar: (options: any) => Promise<any>
}
interface State {
  imageUrl: string | undefined
  fileList: any
}

export interface IStaffFormValues {
  employment_type: EEmploymentType
  firstname: string
  lastname: string
  workinghours: number
  vacationdays: number
  initialVacationDays: number
  validWeekDays: number[]
  useWhiteFont: boolean
  backgroundColor: string
}

export default class StaffForm extends Component<Props, State> {
  private formRef = createRef<FormInstance>()

  constructor(props: Props) {
    super(props)
    this.state = {
      imageUrl: props.imageUrl,
      fileList: props.imageUrl
        ? [
            {
              uid: '-1',
              status: 'done',
              url: props.imageUrl,
            },
          ]
        : [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.customUploadRquest = this.customUploadRquest.bind(this)
  }

  submit() {
    this.formRef.current?.submit()
  }

  beforeUpload(file: RcFile) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  getBase64(img: RcFile, callback: (url: string) => void) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  handleChange(args: any) {
    this.setState({ fileList: args.fileList })
  }

  async customUploadRquest(options: any) {
    const { onSuccess, onError } = options

    try {
      const response = await this.props.replaceAvatar(options)
      unwrapResult(response)
      onSuccess('Ok')
    } catch (e) {
      console.error(e)
      onError({ err: 'Die Datei konnte nicht hochgeladen werden' })
    }
  }

  render() {
    const uploadButton = <Text>Upload</Text>
    return (
      <>
        {this.props.initialValues && (
          <>
            <Text>Avatar</Text>
            <Upload
              name="avatar"
              customRequest={this.customUploadRquest}
              className={styles.avatarUpload}
              listType="picture-card"
              action={`${config.backend.url}/update-avatar`}
              beforeUpload={this.beforeUpload}
              fileList={this.state.fileList}
              onChange={this.handleChange}
            >
              {this.state.fileList.length < 1 && uploadButton}
            </Upload>
          </>
        )}
        <Form
          ref={this.formRef}
          layout="vertical"
          onFinish={(values) => {
            this.props.handleFinish(values)
          }}
          preserve={false}
          initialValues={
            this.props.initialValues ? this.props.initialValues : {
              validWeekDays: [1,2,3,4,5]
            }
          }
        >
          {!this.props.initialValues && (
            <Form.Item
              label="Kategorie"
              name="employment_type"
              rules={[
                {
                  required: true,
                  message: 'Bitte Kategorie auswählen.',
                },
              ]}
            >
              <Select defaultValue={'-?-'} style={{ width: 170 }}>
                <Option value={EEmploymentType.Nurse} key={'1'}>
                  Pflegepersonal
                </Option>
                <Option value={EEmploymentType.Doctor} key={'2'}>
                  Ärztliches Personal
                </Option>
              </Select>
            </Form.Item>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Vorname"
                name="firstname"
                style={{ flexGrow: 1 }}
                rules={[
                  {
                    required: true,
                    message: 'Bitte Vornamen angeben.',
                  },
                ]}
              >
                <Input placeholder="Vorname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Nachname"
                name="lastname"
                style={{ flexGrow: 1 }}
                rules={[
                  {
                    required: true,
                    message: 'Bitte Nachnamen angeben.',
                  },
                ]}
              >
                <Input placeholder="Nachname" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Wochenarbeitsstunden"
                name="workinghours"
                tooltip={
                  {
                    title: 'Änderungen der Wochenarbeitsstunden werden erst ab kommendem Monat wirksam!',
                    icon:
                      <WarningTwoTone
                        style={{ fontSize: '16px' }}
                        twoToneColor="#fc3b02"
                      />
                  }
                }
                rules={[
                  {
                    required: true,
                    message: 'Bitte Wochenarbeitsstunden angeben.',
                  },
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Werktage"
                name="validWeekDays"
                rules={[
                  {
                    required: true,
                    message: 'Bitte auswählen.',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  disabled={!!this.props.initialValues}
                  allowClear
                >
                  <Option value={1} key={'1'}>
                    Mo
                  </Option>
                  <Option value={2} key={'2'}>
                    Di
                  </Option>
                  <Option value={3} key={'3'}>
                    Mi
                  </Option>
                  <Option value={4} key={'4'}>
                    Do
                  </Option>
                  <Option value={5} key={'5'}>
                    Fr
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!this.props.initialValues && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Urlaubstage"
                  name="vacationdays"
                  rules={[
                    {
                      required: true,
                      message: 'Bitte die vertraglichen Urlaubstage angeben.',
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Initiale Urlaubstage"
                  name="initialVacationDays"
                  rules={[
                    {
                      required: true,
                      message: 'Bitte die initialen Urlaubstage angeben.',
                    },
                  ]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={14}>
              <Form.Item label="Hintergrundfarbe" name="backgroundColor"
               rules={[
                 {
                   required: true,
                   message: 'Bitte eine Farbe auswählen.',
                 },
               ]}
              >
                <ColorInput />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Preview"
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.backgroundColor !== curValues.backgroundColor ||
                  prevValues.useWhiteFont !== curValues.useWhiteFont ||
                  prevValues.firstname !== curValues.firstname ||
                  prevValues.lastname !== curValues.lastname
                }
              >
                {({ getFieldValue }) => {
                  const backgroundColor = getFieldValue('backgroundColor')
                  const useWhiteFont = getFieldValue('useWhiteFont')
                  const lastName = getFieldValue('lastname')
                  const firstName = getFieldValue('firstname')
                  return (
                    <Initials
                      firstName={firstName}
                      lastName={lastName}
                      backgroundColor={backgroundColor}
                      useWhiteFont={useWhiteFont}
                    />
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Schriftfarbe"
            name="useWhiteFont"
            valuePropName="checked"
          >
            <Checkbox>Soll weiße Schrift verwendet werden?</Checkbox>
          </Form.Item>
        </Form>
      </>
    )
  }
}
